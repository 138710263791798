<template>
  <div class="map-box">
    <div
      class="bmap"
      :style="{ height: 'calc(100% - ' + (mapBottomHeight + 10) + 'px)' }"
    >
      <!-- <LeafletMap ref="mainMap"></LeafletMap> -->

      <GpsMap ref="historyMap" :mapExpID="mapExpID">
        <el-date-picker
          v-model="selectDate"
          type="date"
          placeholder="Pick a day"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          :teleported="false"
          @change="changePicker"
          @focus="getDateList"
        >
          <template #default="cell">
            <div class="cell" :class="{ current: cell.isCurrent }">
              <span class="text">{{ cell.text }}</span>
              <span v-if="isHoliday(cell)" class="holiday" />
            </div>
          </template>
        </el-date-picker>
      </GpsMap>
    </div>
    <div class="map-bottom cp2" :style="{ height: mapBottomHeight + 'px' }">
      <div class="close" @click="closeMsg"></div>

      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        v-loading="loading"
      >
        <el-tab-pane :label="$t('map.tongji')" name="second">
          <div class="title">
            <div class="it">
              <img
                src="../assets/trace/car.png"
                style="width: 16px; height: 16px; vertical-align: middle"
                alt=""
              />
              <div class="txt">{{ times2str_2(kc_t) }}</div>
            </div>
            <div class="it">
              <img
                src="../assets/trace/line.png"
                style="width: 16px; height: 16px; vertical-align: middle"
              />
              <div class="txt">{{ num_process(dl_t) }} Trip</div>
            </div>
            <div class="it">
              <img
                src="../assets/trace/p.png"
                style="width: 16px; height: 16px; vertical-align: middle"
              />
              <div class="txt">{{ times2str_2(tc_t) }}</div>
            </div>
          </div>
          <div class="timeline">
            <div
              :class="{ item: true, act: act_idx == it.idx }"
              v-for="(it, idx) in list_trace"
              :key="idx"
              @click="car_sel(it)"
            >
              <div class="tt time">
                {{ it.time }}
                <img
                  v-if="it.name == 'end'"
                  src="../assets/trace/car.png"
                  style="
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 4px;
                    right: 0;
                    z-index: 10000;
                  "
                /><img
                  v-if="it.name == 'start'"
                  src="../assets/trace/p.png"
                  style="
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 4px;
                    right: 0;
                    z-index: 10000;
                  "
                />
              </div>
              <div class="tt runtime">
                <img
                  src="../assets/trace/b.png"
                  style="
                    width: 15px;
                    height: 15px;
                    vertical-align: middle;
                    margin-top: 7px;
                  "
                />
                <div class="txt">{{ it.runtime }}</div>
              </div>
              <div class="tt dis" v-if="it.name == 'end'">
                <img
                  src="../assets/trace/t.png"
                  style="
                    width: 15px;
                    height: 15px;
                    vertical-align: middle;
                    margin-top: 7px;
                  "
                />
                <div class="txt">{{ it.dis }}</div>
              </div>
              <div class="tt speed" v-if="it.name == 'end'">
                <img
                  src="../assets/trace/s.png"
                  style="
                    width: 15px;
                    height: 15px;
                    vertical-align: middle;
                    margin-top: 7px;
                  "
                />
                <div class="txt">{{ it.speed }}</div>
              </div>
              <div class="tt">
                <img
                  src="../assets/trace/a.png"
                  style="
                    width: 18px;
                    height: 18px;
                    vertical-align: middle;
                    margin-top: 7px;
                  "
                />
                <div class="txt" v-if="it.name !== 'end'">
                  {{ it.addr }}
                </div>
                <div class="txt" v-if="it.name == 'end'">
                  {{ it.addr }}
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane :label="$t('map.xiangqing')" name="first">
          <div style="text-align: right">
            <el-button
              type="primary"
              size="default"
              class="daochu"
              @click="btnExport"
              >{{ $t("map.daochu") }}</el-button
            >
          </div>

          <el-table
            v-loading="loading"
            :data="dataHistory"
            border
            stripe
            :height="mapBottomHeight - 50"
            @row-click="onRowClick"
            :row-class-name="tableRowClass"
          >
            <el-table-column
              show-overflow-tooltip
              align="center"
              v-for="col in columns"
              :prop="col.field"
              :key="col.field"
              :label="col.label"
              :width="col.width"
            >
              <template #default="{ row }">
                <span
                  v-if="col.render && typeof col.render == 'function'"
                  v-html="col.render(row)"
                ></span>
                <span v-else>{{ row[col.field] }}</span>
              </template>
            </el-table-column>
          </el-table></el-tab-pane
        >

        <el-tab-pane :label="$t('map.baobiao')" name="third">
          <div style="text-align: right">
            <el-button
              type="primary"
              size="default"
              class="daochu"
              @click="btnExportPdf"
              >{{ $t("map.daochu") }}</el-button
            >
          </div>

          <div
            v-html="report_html"
            v-loading="loading_report"
            style="min-height: 50px"
          ></div>
        </el-tab-pane>
      </el-tabs>

      <!-- {{ $t("map.rImg") }} -->
    </div>
  </div>
</template>

<script>
// import LeafletMap from "./LeafletMapHistory.vue";
import moment from "moment";
import GpsMap from "./GpsMapHistory.vue";
import {
  formatDate,
  getDir,
  str2time,
  dateFormat,
  km2mph2,
  dateCalc,
  km2mp2,
  dateFormatByDateStr,
  getTotalSec,
  times2str,
} from "../utils/tools.js";
export default {
  components: { GpsMap },
  props: {
    devID: {
      type: String,
      default: "",
    },
    cph: {
      type: String,
      default: "",
    },
    mapExpID: {
      type: String,
      default: "gmh",
    },
  },
  // watch: {
  //   devID(val) {
  //     this.getDateList();
  //   },
  // },
  mounted() {},
  data() {
    return {
      holidays: ["2024-09-10"],
      loading_report: false,
      loading: false,
      Unit: 1,
      timer: null,
      lnglat_idx: 0,
      curr_car: 0,
      report_html: "",
      list_line: [],
      list_trace: [],
      act_idx: 0,
      kc_t: 0,
      tc_t: 0,
      dl_t: 0,
      activeName: "second",
      dataHistory: [],
      selectDate: moment().format("YYYY-MM-DD"), //"2023-12-10",
      columns: [
        {
          field: "cph",
          label: this.$t("map.cph_name"),
          width: "120px",
          render: (row) => {
            let rt = row["cph"];
            // if (row.pMsgType == "1796") {
            //   rt += "(补)";
            // }
            return rt;
          },
        },
        { field: "pGpsTime", label: this.$t("map.track_sj"), width: "200px" },
        {
          field: "pSpeed_u",
          label: this.$t("map.track_sd"),
          width: "100px",
        },
        {
          field: "pMileage_u",
          label: this.$t("map.track_dslc"),
          width: "200px",
        },
        {
          field: "addr",
          label: this.$t("map.track_wz"),
          width: "100px",
        },
        // {
        //   field: "pBaiDuPos",
        //   label: this.$t("map.track_wz"),
        //   width: "100px",
        //   render: (row) => {
        //     // console.log(row);
        //     // this.get_gps(row, "pLongitude", "pLatitude", "addr");
        //     row.addr = row.pLongitude + "," + row.pLatitude;
        //     return row.addr;
        //   },
        // },
        {
          field: "pStatus",
          label: this.$t("map.track_zt"),
          width: "200px",
          render: (row) => {
            if (row == "") return "";
            var str = row["pStatus"];
            var str_2 = parseInt(str).toString(2);

            var ay = str_2.split("");
            ay.reverse();
            // console.log(this.$t("map.s_1.s0"));
            let msg = this.$t("map.s_1.s" + ay[0]);
            msg += this.$t("map.gps_" + row["islatlng"]); //lang["gps_" + row["islatlng"]];
            //this.$t("map.yx")
            if (row["pNetType"] >= 0) {
              msg +=
                "," +
                [
                  this.$t("map.yx"),
                  "4G",
                  "WIFI",
                  "5G",
                  "3G",
                  "2G",
                  this.$t("map.wz"),
                ][row["pNetType"]];
            }

            var xh = this.$t("map.yxh");
            if (
              row["pWireNetSignValue"] > 0 &&
              row["pWireNetSignValue"] <= 13
            ) {
              xh = this.$t("map.xhc");
            }
            if (
              row["pWireNetSignValue"] > 13 &&
              row["pWireNetSignValue"] <= 20
            ) {
              xh = this.$t("map.xhyb");
            }
            if (row["pWireNetSignValue"] > 20) {
              xh = this.$t("map.xhh");
            }
            msg += "," + xh;

            if (row["pGNSSStarCount"] > 0) {
              msg += "," + this.$t("map.wx") + "：" + row["pGNSSStarCount"];
            }

            if (row["pRecordStatus"] != "" && row["pRecordStatus"] != null) {
              var str_str = row["pRecordStatus"];
              var str_ay = str_str.split(",");
              var result_ay = [];
              for (var i = 0; i < str_ay.length; i++) {
                if (str_ay[i] != "") {
                  result_ay.push("CH" + str_ay[i]);
                }
              }
              msg += "," + result_ay.join(",");
            }

            var car_zt = row["pExtStatus"];
            var car_zt_2 = parseInt(car_zt).toString(2);
            var car_ay = car_zt_2.split("");
            car_ay.reverse();

            if (car_ay[2] == 1) {
              msg += "," + this.$t("map.yz");
            }
            if (car_ay[3] == 1) {
              msg += "," + this.$t("map.zz");
            }
            if (car_ay[4] == 1) {
              msg += "," + this.$t("map.cc");
            }
            if (car_ay[5] == 1) {
              msg += "," + this.$t("map.dc");
            }
            return msg;
            // pos = msg;
          },
        },
        {
          field: "pAlarmMsg",
          label: this.$t("map.track_bj"),
          render: (row) => {
            // console.log(row);
            //101,103
            var ay_str = row["pAlarmMsg"];

            var ay_ay = ay_str.split(",");
            let result_ay = [];

            var ay_result = [];
            var str_temp = "";
            var str_a, ay_a, j;
            var str_str, str_ay;
            for (var i = 0; i < ay_ay.length; i++) {
              if (ay_ay[i] >= 217 && ay_ay[i] <= 224) continue;
              if (ay_ay[i] == "") continue;
              str_temp = this.$t("map.e_" + ay_ay[i]);
              if (ay_ay[i] == 101) {
                str_a = row["pVideoLossStatus"];
                ay_a = str_a.split(",");
                for (j = 0; j < ay_a.length; j++) {
                  ay_a[j] = "CH" + ay_a[j];
                }
                str_temp += ay_a.join(",");
              } else if (ay_ay[i] == 103) {
                str_str = row["pVideoMemoryFailureStatus"];
                str_ay = str_str.split(",");
                result_ay = [];
                for (var j = 0; j < str_ay.length; j++) {
                  if (str_ay[j] != "") {
                    if (str_ay[j] == 1) {
                      result_ay.push("HDD");
                    } else {
                      if (str_ay[j] == 2) {
                        result_ay.push("SD");
                      } else {
                        result_ay.push("SD" + (parseInt(str_ay[j]) - 1));
                      }
                    }
                  }
                }
                str_temp += result_ay.join(",");
              } else if (ay_ay[i] == 102) {
                str_a = row["pVideoBlockingStatus"];
                ay_a = str_a.split(",");
                for (j = 0; j < ay_a.length; j++) {
                  ay_a[j] = "CH" + ay_a[j];
                }
                str_temp += ay_a.join(",");
              }

              ay_result.push(str_temp);
            }

            //不录像
            var str_blx = row["pRecordState"];
            if (str_blx != "") {
              var str_temp_blx = this.$t("map.blx") + ",";
              var ay_blx = str_blx.split(",");
              for (var i = 0; i < ay_blx.length; i++) {
                ay_blx[i] = "CH" + ay_blx[i];
              }
              str_temp_blx += ay_blx.join(",");
              ay_result.push(str_temp_blx);
            }

            var str_output = ay_result.join(",");

            str_output = str_output.replace(/(^[\s,]*)|([\s,]*$)/g, "");

            return '<span class="tree_red">' + str_output + "</span>";
            // return this.$t("map.e_" + row.pAlarmMsg);
          },
        },
      ],
      mapBottomHeight: 400,
    };
  },
  methods: {
    getDateList() {
      if (this.devID == "") return;
      this.holidays.splice(0);
      // console.log(this.selectDate);
      const [year, month] = this.selectDate.split("-").slice(0, 2);
      const newDate = `${year}-${month}`;
      this.$api
        .search_get_devices_state({ deviceID: this.devID, date: newDate })
        .then((res) => {
          if (res.data.data) {
            this.holidays = res.data.data.map((it) => it.dDate);
          }
          console.log("sss", res.data);
        });
    },
    isHoliday({ dayjs }) {
      return this.holidays.includes(dayjs.format("YYYY-MM-DD"));
    },
    btnExportPdf() {
      this.$api
        .pdf_wap_report_pc({
          deviceid: this.devID,
          sdate: this.selectDate + " 00:00:00",
          edate: this.selectDate + " 23:59:59",
          cph: this.cph,
          lang: localStorage.lang,
        })
        .then((res) => {
          // 处理返回的文件流
          const content = res.data;
          const blob = new Blob([content], {
            type: "application/pdf;charset=utf-8",
          });

          const fileName = this.cph + "_Trip Report." + "pdf";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        });
    },
    btnExport() {
      this.$api
        .search_track_grid_excel({
          deviceid: this.devID,
          sdate: this.selectDate + " 00:00:00",
          edate: this.selectDate + " 23:59:59",
          cph: this.cph,
        })
        .then((res) => {
          // 处理返回的文件流
          const content = res.data;
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });

          const fileName = this.cph + "_history." + "xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        });
    },
    closeTimeout() {
      this.lnglat_idx = this.dataHistory_temp.length;
      if (this.timer) clearTimeout(this.timer);
    },
    get_gps(it, lng, lat, to) {
      setTimeout(() => {
        this.$api
          .bdmap_get_gps_addr({
            lng: parseFloat(it[lng]),
            lat: parseFloat(it[lat]),
          })
          .then((res_addr) => {
            it[to] = res_addr.data.formatted_address;
          });
      }, 1000);
    },
    tableRowClass({ row, rowIndex }) {
      if (
        row.pVideoLossStatus != "" ||
        row.pAlarmMsg != "" ||
        row.pVideoMemoryFailureStatus != "" ||
        row.pVideoBlockingStatus != "" ||
        row.pRecordState != ""
      ) {
        if (row.pMsgType == "1796") {
          return "row-red row-red2";
        } else {
          return "row-red";
        }
      } else if (row.pMsgType == "1796") {
        return "row-red2";
      }
      return "";
    },
    car_sel(it) {
      console.log("car_sel", it);
      this.act_idx = it.idx;

      let ay = [];

      if (typeof this.list_line[it.idx] !== "undefined") {
        this.list_line[it.idx].forEach((it) => {
          ay.push({
            addr: it.address,
            dir: getDir(parseFloat(it.direction)),
            lng: parseFloat(it.lng),
            lat: parseFloat(it.lat),
            speed: km2mph2(it.speed, this.Unit),
            icon: it.icon,
            time: it.pGpsTime,
            cph: it.carnumber,
            ang: parseFloat(it.pDirection),
          });
        });

        this.$refs.historyMap.drawCarRoad(ay);

        console.log("select", ay);
      }
    },
    num_process(val) {
      return (val * 0.1).toFixed(2);
    },
    times2str_2(val) {
      return times2str(val);
    },
    handleClick(tab, event) {
      switch (tab.paneName) {
        case "first":
          if (this.dataHistory.length > 0) {
            this.lnglat_idx = 0;
            this.parseLnglat();
          }
          break;
        case "second":
          break;
        case "third":
          this.loading_report = true;
          this.$api
            .pdf_wap_report_html({
              page: 1,
              rp: 500,
              deviceid: this.devID,
              sdate: this.selectDate + " 00:00:00",
              edate: this.selectDate + " 23:59:59",
              cph: this.cph,
              lang: localStorage.lang,
            })
            .then((res) => {
              // console.log(res);
              this.loading_report = false;
              this.report_html = res.data.html;
            });
          break;
      }
    },
    changePicker(date) {
      // console.log(date);
      this.selectDate = date;
      this.getDateList();
      this.select_date_fun();
    },
    onRowClick(row) {
      console.log("history", row);
      this.$refs.historyMap.posCar({
        lat: parseFloat(row.pLatitude),
        lng: parseFloat(row.pLongitude),
        time: row.pGpsTime,
        ang: parseFloat(row.pDirection),
        cph: row.cph,
      });

      if (row.addr.indexOf(",") > -1) {
        console.log(row.addr);
        // this.$api
        //   .bdmap_get_gps_addr({
        //     lng: parseFloat(row.pLongitude),
        //     lat: parseFloat(row.pLatitude),
        //   })
        //   .then((res_addr) => {
        //     this.dataHistory.forEach((it) => {
        //       if (it.ID == row.ID) {
        //         it.addr = res_addr.data.formatted_address;
        //       }
        //     });
        //     // row.addr = res_addr.data.formatted_address;
        //   });
      }
    },
    select_date_fun() {
      this.loading = true;
      this.$api
        .search_track_grid({
          page: 1,
          rp: 50000,
          deviceid: this.devID,
          sdate: this.selectDate + " 00:00:00",
          edate: this.selectDate + " 23:59:59",
          cph: this.cph,
        })
        .then((res) => {
          this.Unit = res.data.Unit;
          res.data.data.forEach((it) => {
            // it.addr = "";
            it.addr = it.pLongitude + "," + it.pLatitude;
          });
          this.loading = false;
          this.dataHistory = res.data.data;
          this.dataHistory_temp = res.data.data.concat();
          this.processData(res.data.data);
          // console.log(this.dataHistory);
          //地图经纬度
          let ay = [];
          this.dataHistory.forEach((it) => {
            if (it.pLatitude != 0 && it.pLongitude != 0)
              // ay.push([parseFloat(it.pLatitude), parseFloat(it.pLongitude)]);
              ay.push({
                lat: parseFloat(it.pLatitude),
                lng: parseFloat(it.pLongitude),
                time: it.pGpsTime,
                ang: parseFloat(it.pDirection),
                cph: it.cph,
              });
          });
          this.$refs.historyMap.drawCarRoad(ay);
          // if (this.dataHistory.length > 0) {
          //   this.lnglat_idx = 0;
          //   this.parseLnglat();
          // }

          // setTimeout(() => {
          //   this.dataHistory.forEach(async (it) => {
          //     // let res_addr = await this.$api.bdmap_get_gps_addr({
          //     //   lng: parseFloat(it.pLongitude),
          //     //   lat: parseFloat(it.pLatitude),
          //     // });
          //     // if (res_addr.data.formatted_address) {
          //     //   it.addr = res_addr.data.formatted_address;
          //     // }
          //     // this.get_gps(it, "pLongitude", "pLatitude", "addr");
          //     // this.$api
          //     //   .bdmap_get_gps_addr({
          //     //     lng: parseFloat(it.pLongitude),
          //     //     lat: parseFloat(it.pLatitude),
          //     //   })
          //     //   .then((res_addr) => {
          //     //     it.addr = res_addr.data.formatted_address;
          //     //   });
          //   });
          // }, 20000);

          //位置解析
          // this.dataHistory.forEach((row) => {
          //   this.get_gps(row, "pLongitude", "pLatitude", "addr");
          // });
          // console.log(ay);
        });
    },
    parseLnglat() {
      let row = this.dataHistory_temp[this.lnglat_idx];
      this.$api
        .bdmap_get_gps_addr({
          lng: parseFloat(row.pLongitude),
          lat: parseFloat(row.pLatitude),
        })
        .then((res_addr) => {
          row.addr = res_addr.data.formatted_address;
          if (this.lnglat_idx < this.dataHistory.length - 1) {
            this.lnglat_idx++;
            if (this.lnglat_idx % 100 == 0) {
              this.dataHistory = this.dataHistory_temp.concat();
            }
            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(() => {
              this.parseLnglat();
            }, 1);
          } else {
            this.dataHistory = this.dataHistory_temp;
          }
        });
    },
    processData(dataList) {
      let ay = [];

      //{id:1,time:'01:29:00',speed:0,runtime:0,dis:0},

      let ay_tarce = [];
      let ay_line = [];
      let ay_temp = [];

      let start_0 = 0;

      let kk = 0;
      let runtime_end = 0;
      let dis_end = 0;
      let time_end = 0;
      let total_sec = 0;

      let is_firt_list = 0;
      //
      let ay_0_num = [];
      let ay_0_num_flag = 0;
      let is_tingche = 0;

      dataList.forEach((it, idx) => {
        it.lng = parseFloat(it.pLongitude);
        it.lat = parseFloat(it.pLatitude);
        it.pSpeed_u = it.pSpeed;
        it.pSpeed = it.pSpeed_val;
        it.pMileage_u = it.pMileage;
        it.pMileage = it.pMileage_val;
        if (parseFloat(it.lng) != 0 && parseFloat(it.lat) != 0) {
          // ay.push({
          // 	addr:it.address,
          // 	dir:getDir(parseFloat(it.direction)),
          // 	lng:parseFloat(it.lng),
          // 	lat:parseFloat(it.lat),
          // 	speed:km2mph(it.speed),
          // 	icon:it.icon,
          // 	time:it.pGpsTime,
          // 	cph:it.carnumber,
          // })

          runtime_end = 0;
          dis_end = 0;
          time_end = 0;
          total_sec = 0;

          /////////
          if (is_firt_list == 0) {
            is_firt_list = 1;
            ay_temp = [];
            ay_line.push({
              speed_num: 0,
              runtime_num: 0,
              dis_num: 0,
              lng: it.lng,
              lat: it.lat,
              addr: "",
              act: "",
              time: dateFormatByDateStr("HH:MM", it.pGpsTime),
              speed: km2mph2(it.pSpeed, this.Unit),
              runtime: "0 min",
              dis: km2mp2(0, this.Unit),
              idx: kk,
              name: "start",
            });
            ay_temp.push(it);
            start_0 = 1;
          } else if (idx == dataList.length - 1) {
            // runtime_end = dateCalc(ay_temp[0].pGpsTime,ay_temp[ay_temp.length-1].pGpsTime);
            // dis_end = parseFloat(ay_temp[ay_temp.length-1].pMileage) - parseFloat(ay_temp[0].pMileage);
            // time_end = dateFormatByDateStr('HH:MM',ay_temp[ay_temp.length-1].pGpsTime);
            // ay_line.push({time:time_end,speed:km2mph(ay_temp[ay_temp.length-1].pSpeed),runtime:runtime_end,dis:dis_end,idx:kk,name:'end'});
            // ay_tarce.push(ay_temp);
            // ay_temp = [];
            // start_0 = 1;
          } else {
            if (start_0 == 1) {
              if (it.pSpeed > 0) {
                //算停留时间
                runtime_end = dateCalc(ay_temp[0].pGpsTime, it.pGpsTime);
                this.tc_t += getTotalSec(ay_temp[0].pGpsTime, it.pGpsTime);
                ay_line[ay_line.length - 1].runtime = runtime_end;
                ay_line[ay_line.length - 1].runtime_num = getTotalSec(
                  ay_temp[0].pGpsTime,
                  it.pGpsTime
                );

                ay_tarce.push(ay_temp);
                ay_temp = [];

                //开车时间开始
                kk = kk + 1;
                ay_temp.push(it);
                ay_line.push({
                  speed_num: 0,
                  runtime_num: 0,
                  dis_num: 0,
                  lng: it.lng,
                  lat: it.lat,
                  addr: "",
                  act: "",
                  time: dateFormatByDateStr("HH:MM", it.pGpsTime),
                  speed: km2mph2(it.pSpeed, this.Unit),
                  runtime: "0 min",
                  dis: km2mp2(0, this.Unit),
                  idx: kk,
                  name: "end",
                });

                start_0 = 0;
              } else {
                ay_temp.push(it);
              }
            } else {
              if (it.acc == 1) {
                if (ay_0_num_flag == 1) {
                  ay_0_num.push(it.pSpeed);
                  if (ay_0_num.length > 10) {
                    is_tingche = 1;
                    ay_0_num.forEach((it) => {
                      if (it > 0) {
                        is_tingche = 0;
                      }
                    });

                    //未停车重新计算
                    if (is_tingche == 0) {
                      ay_0_num_flag = 0;
                      is_tingche = 0;
                      ay_0_num = [];
                    }
                  }
                }

                if (parseFloat(it.pSpeed) == 0 && ay_0_num_flag == 0) {
                  is_tingche = 0;
                  ay_0_num_flag = 1;
                  ay_0_num.push(it.pSpeed);
                }
              } else {
                ay_0_num_flag = 0;
                is_tingche = 0;
                ay_0_num = [];
                is_tingche = 1;
              }

              // if(parseFloat(it.pSpeed)==0){
              if (is_tingche == 1) {
                // ay_line.push(ay_temp[ay_temp.length-1]);

                runtime_end = dateCalc(ay_temp[0].pGpsTime, it.pGpsTime);
                this.kc_t += getTotalSec(ay_temp[0].pGpsTime, it.pGpsTime);

                total_sec = getTotalSec(ay_temp[0].pGpsTime, it.pGpsTime);

                dis_end =
                  parseFloat(it.pMileage) - parseFloat(ay_temp[0].pMileage);
                // console.log(kk,dis_end)
                this.dl_t += dis_end;
                ay_line[ay_line.length - 1].runtime = runtime_end;
                ay_line[ay_line.length - 1].dis = km2mp2(dis_end, this.Unit);
                ay_line[ay_line.length - 1].speed = km2mph2(
                  (dis_end / total_sec) * 3600,
                  this.Unit
                );

                ay_line[ay_line.length - 1].runtime_num = total_sec;
                ay_line[ay_line.length - 1].dis_num = dis_end;
                ay_line[ay_line.length - 1].speed_num =
                  (dis_end / total_sec) * 3600;

                ay_tarce.push(ay_temp);
                ay_temp = [];
                // time_end = dateFormatByDateStr('HH:MM',ay_temp[ay_temp.length-1].pGpsTime);

                // ay_line.push({time:time_end,speed:km2mph(ay_temp[ay_temp.length-1].pSpeed),runtime:runtime_end,dis:dis_end,idx:kk,name:'end'});
                kk = kk + 1;
                ay_temp.push(it);
                ay_line.push({
                  speed_num: 0,
                  runtime_num: 0,
                  dis_num: 0,
                  lng: it.lng,
                  lat: it.lat,
                  addr: "",
                  act: "",
                  time: dateFormatByDateStr("HH:MM", it.pGpsTime),
                  speed: km2mph2(0, this.Unit),
                  runtime: "0 min",
                  dis: km2mp2(0, this.Unit),
                  idx: kk,
                  name: "start",
                });

                // ay_temp = [];
                start_0 = 1;
              } else {
                ay_temp.push(it);
              }
            }
          }

          ////////////////////////////////
        }
      });

      ay_tarce.push(ay_temp);

      this.list_trace = Object.assign(this.list_trace, ay_line);
      this.list_line = Object.assign(this.list_line, ay_tarce);

      let trace_trace = [];
      let line_line = [];

      let item_prev;
      if (this.list_trace.length > 0) {
        let kk_kk = 0;
        for (let i = 0; i < this.list_trace.length; i++) {
          if (i > 0 && this.list_trace[i].runtime_num < 300) {
            item_prev = trace_trace[trace_trace.length - 1];

            if (i + 1 < this.list_trace.length) {
              item_prev.runtime_num =
                item_prev.runtime_num +
                this.list_trace[i].runtime_num +
                this.list_trace[i + 1].runtime_num;

              item_prev.dis_num =
                item_prev.dis_num +
                this.list_trace[i].dis_num +
                this.list_trace[i + 1].dis_num;
              item_prev.speed_num =
                (item_prev.speed_num + this.list_trace[i + 1].speed_num) * 0.5;

              //处理轨迹
              line_line[line_line.length - 1] = line_line[
                line_line.length - 1
              ].concat(this.list_line[i], this.list_line[i + 1]);
            } else {
              item_prev.runtime_num =
                item_prev.runtime_num + this.list_trace[i].runtime_num;
              line_line[line_line.length - 1] = line_line[
                line_line.length - 1
              ].concat(this.list_line[i]);

              //处理轨迹

              // item_prev.dis_num =  item_prev.dis_num + this.list_trace[i].dis_num;
              // item_prev.speed_num =  (item_prev.speed_num + this.list_trace[i+1].speed_num)*0.5;
            }

            item_prev.runtime = times2str(item_prev.runtime_num);
            item_prev.dis = km2mp2(item_prev.dis_num, this.Unit);
            item_prev.speed = km2mph2(
              (item_prev.dis_num / item_prev.runtime_num) * 3600,
              this.Unit
            );
            trace_trace[trace_trace.length - 1] = item_prev;

            i = i + 1;
          } else {
            this.list_trace[i].idx = kk_kk;
            trace_trace.push(this.list_trace[i]);
            line_line.push(this.list_line[i]);
            kk_kk++;
          }
        }
      }

      // console.log(this.list_trace)
      // console.log('list_trace',this.list_trace)
      // console.log("list_trace", trace_trace);
      // console.log("list_line", line_line);

      this.list_trace = trace_trace;
      this.list_line = line_line;

      if (trace_trace.length > 0) {
        trace_trace.forEach(async (ik) => {
          let res_res = await this.$api.bdmap_get_gps_addr({
            lng: parseFloat(ik.lng),
            lat: parseFloat(ik.lat),
          });
          // console.log("list_trace", res_res);
          ik.addr = res_res.data.formatted_address;
        });
        this.list_trace = trace_trace;
      }
      // console.log('list_line',this.list_line)

      if (this.list_line.length > 0) {
        this.list_line[0].forEach((it) => {
          ay.push({
            addr: it.address,
            dir: getDir(parseFloat(it.direction)),
            lng: parseFloat(it.lng),
            lat: parseFloat(it.lat),
            speed: km2mph2(it.speed, this.Unit),
            icon: it.icon,
            time: it.pGpsTime,
            cph: it.carnumber,
          });
        });

        // this.sender.data = {
        //   map_idx: -2,
        //   page: 1,
        //   ay: ay,
        // };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.demo-date-picker {
  display: flex;
  justify-content: space-between;
}

.cell {
  height: 30px;
  padding: 3px 0;
  box-sizing: border-box;
}

.cell .text {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto;
  line-height: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}

.cell.current .text {
  background: #626aef;
  color: #fff;
}

.cell .holiday {
  position: absolute;
  width: 6px;
  height: 6px;
  background: var(--el-color-danger);
  border-radius: 50%;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.map-box {
  position: relative;
  height: calc(100vh - 125px);
  width: 100%;
  height: 100%;
  .bmap {
    position: relative;
    .search-box {
      position: absolute;
      top: 10px;
      left: 50px;
      background: #fff;
      z-index: 10000000;
    }
  }
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  line-height: 25px;
  background: #d3e0ef;
  border: 1px solid #ccc;
  color: #333;
  .txt {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: bottom;
    padding-left: 5px;
    height: 25px;
    line-height: 28px;
  }
}
.timeline {
  position: relative;
  border: 1px solid #ccc;
  padding-top: 5px;
}
.xx {
  position: absolute;
  content: "";
  width: 1px;
  background: linear-gradient(to bottom, green, red) !important;
  height: 30px;
  left: 80px;
  top: 15px;
}
.timeline .item:nth-child(even) .xx {
  //margin-bottom:20px;
  background: none !important;
}
.timeline .item:nth-child(odd) button {
  //margin-bottom:20px;
  display: none !important;
}
.timeline .item:nth-child(even) button {
  //margin-bottom:20px;
  background: #2485e7 !important;
  border-radius: 10px;
}
.timeline .item:nth-child(even) {
  //margin-bottom:20px;
  //border-bottom:1px solid #ccc
}
.runtime {
  padding-left: 20px !important;
}
.timeline {
  // height: calc();
  .act {
    background: #eef3f8 !important;
    color: #333;
    border: 1px solid #ccc;
    border-top: none;
  }
  .item {
    height: 30px;
    line-height: 30px;
    border: 1px solid #ccc;
    border-top: none;
    display: flex;
    flex-direction: row;
    .time {
      width: 90px;
      text-align: center;
      position: relative;
      padding-right: 20px !important;
      .tb {
        position: absolute;
        right: 0;
        top: 2px;
        z-index: 10;
      }
      &::after {
        position: absolute;
        content: "";
        width: 1px;
        background: #222222;
        height: 30px;
        right: 8px;
        top: 0;
        z-index: 5;
      }
    }
    .tt {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      text-align: center;
      line-height: 25px;
      height: 25px;
      padding: 0 0 0 5px;
      .txt {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: bottom;
        padding-left: 2px;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}
::v-deep .el-table th.el-table__cell {
  background: #eef3f8;
  color: #333;
}
::v-deep .el-table--border .el-table__cell {
  border-right: 1px solid #fafafa;
}
::v-deep .row-red {
  color: red;
}
::v-deep .row-red2 {
  background: #ebd6a2 !important;
}
::v-deep .row-red2 td.el-table__cell {
  background: #ebd6a2 !important;
}
.daochu {
  border-radius: 30px;
  background: #2485e7 !important;
  height: 25px;
  font-size: 12px;
  margin: 5px 0px;
}
</style>
